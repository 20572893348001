.app__aboutus {
    position: relative;
    height: 70vh;
}

.app__aboutus-overlay {
    position: absolute;
    inset: 0;
}

.app__aboutus-overlay img {
    width: 191px;
    height: 165px;
    z-index: 100;
    margin-top: -350px;
}

.app__aboutus-content {
    width: 100%;
    z-index: 2;
    align-items: center;
    flex-direction: column;
}

.app__aboutus-content_about {
    flex: 1;
    display: flex;
    /* justify-content: flex-end; */
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.app__aboutus-content_knife {
    margin: 2rem 4rem;
}

.app__aboutus-content_knife img {
    height: 910px;
}

.app__aboutus-content_history {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    text-align: left;
}

.app__aboutus-content_about p,
.app__aboutus-content_history p {
    margin: 2rem 0;
    color: var(--color-black);
    font-weight: 500;
    max-width: 80%;
}

@media screen and (min-width: 2000px) {
    .app__aboutus-content_knife img {
        height: 1110px;
    }

    .app__aboutus-content_about p,
    .app__aboutus-content_history p {
        margin: 4rem 0;
    }
}
@media screen and (max-width: 1150px) {
    .app__aboutus-overlay img {
        display: none;
    }

    .app__aboutus-content_about {
        padding-top: 0px; /* Add some padding to the header for more space */
    }
}
@media screen and (max-width: 900px) {
    .app__aboutus-content {
        flex-direction: column;
    }

    .app__aboutus-content_about {
        padding-top: 0px; /* More padding for smaller screens */
    }

    .app__aboutus-overlay img {
       display: none;
    }
}

@media screen and (max-width: 650px) {
    .app__aboutus-overlay img {
       display: none;
    }

    .app__aboutus-content_about {
        padding-top: 0px; /* Extra padding on very small screens */
    }
}




