#gallery {
  padding-top: 40px;
  margin-top: 80px; /* Adds space between the navbar and gallery section */
}

@media screen and (min-width: 991px) {
  #gallery {
      padding: 60px 30px 0 30px;
      margin-top: 80px; /* Adjusts the margin for larger screens */
  }
}

/* Layout for the gallery */
#image-gallery .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

/* Default to single column layout */
.image {
  flex: 1 1 100%;
  margin: 10px 0;
}

/* Two columns layout for screens 768px and above */
@media screen and (min-width: 768px) {
  .image {
      flex: 1 1 calc(50% - 20px);
  }
  .img-wrapper img {
    width: 70%; /* Adjust this value as needed */
    height: auto; /* Maintain aspect ratio */
    margin: 0 auto; /* Center the image */
    display: block;
}
}

/* Four columns layout for screens 1150px and above */
@media screen and (min-width: 1150px) {
  .image {
      flex: 1 1 calc(25% - 20px);
  }
}

.img-wrapper {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}

.img-wrapper img {
  width: 100%;
  height: auto;
  display: block;
}

.img-overlay {
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.img-overlay i {
  color: #fff;
  font-size: 2em;
}

#overlay {
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  user-select: none;
}

#overlay img {
  margin: 0;
  width: 80%;
  height: auto;
  object-fit: contain;
  padding: 5%;
}

@media screen and (min-width: 768px) {
  #overlay img {
      width: 60%;
  }
}

@media screen and (min-width: 1200px) {
  #overlay img {
      width: 50%;
  }
}

#nextButton, #prevButton, #exitButton {
  color: #fff;
  font-size: 2em;
  transition: opacity 0.8s;
}

#nextButton:hover, #prevButton:hover, #exitButton:hover {
  opacity: 0.7;
}

@media screen and (min-width: 768px) {
  #nextButton, #prevButton, #exitButton {
      font-size: 3em;
  }
}

#exitButton {
  position: absolute;
  top: 15px;
  right: 15px;
}
