.section__padding {
  padding: 4rem 6rem;
}

.flex__center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__bg {
  background: #ffffff;
  background-position: center;
  background-size: cover;
  background-repeat: repeat;
  background-attachment: fixed;
}
.app__bg1 {
  background: #ffffff;
  background-position: center;
  background-size: cover;
  background-repeat: repeat;
  background-attachment: fixed;
}

.app__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;
}

.app__wrapper_info {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 10rem;
}

.app__wrapper_info1 {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.app__wrapper_img {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: 10rem;
}

.app__wrapper_img-reverse {
  justify-content: flex-start;
  margin-right: 2rem;
}

.app__wrapper_img img {
  width: 80%;
}

.custom__button {
  background-color: var(--color-black);
    color: var(--color-white);
    font-family: var(--font-base);
    font-weight: 500;
    letter-spacing: 0.04em;
    line-height: 28px;
    font-size: 16px;
    padding: 0.5rem 1.5rem;
    border-radius: 1px;
    border: none;
    outline: none;
    cursor: pointer;
}
.custom__button1 {
  background-color: var(--color-black);
    color: var(--color-white);
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    letter-spacing: 0.04em;
    line-height: 28px;
    font-size: 16px;
    padding: 0.5rem 1.5rem;
    border-radius: 1px;
    border: none;
    outline: none;
    cursor: pointer;
}

.p__cormorant {
  font-family: 'Roboto', sans-serif;
  color: #000000;
  font-weight: 900;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  font-feature-settings: 'tnum' on, 'lnum' on;
  line-height: 29.9px;
  font-size: 25px;
}
.p__cormorant1 {
  font-family: 'Roboto', sans-serif;
  color: #ffffff;
  font-weight: 900;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  font-feature-settings: 'tnum' on, 'lnum' on;
  line-height: 29.9px;
  font-size: 25px;
}

.p__opensans {
  font-family: 'Roboto', sans-serif;
  color: var(--color-black);
  font-weight: 400;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  line-height: 28px;
  font-size: 16px;
}
.p__opensans1 {
  font-family: 'Roboto', sans-serif;
  color: var(--color-white);
  font-weight: 400;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  line-height: 28px;
  font-size: 16px;
}

.headtext__cormorant {
  font-family: var(--font-base);
  color: var(--color-black);
  font-size: 64px;
  line-height: 83.2px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  font-weight: 700;
}
.headtext__cormorant1 {
  font-family: var(--font-base);
  color: var(--color-white);
  font-size: 64px;
  line-height: 83.2px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  font-weight: 700;
}

.spoon__img {
  width: 45px;
}

.app__menu1 {
  margin-top: 180px; /* Adjust this value based on the height of your navbar */
  padding: 20px;
  text-align: center;
}

.app__menu-heading1 {
  font-size: 4rem;
  margin-bottom: 40px;
  font-weight: 400;
}

.app__specialMenu-images1 {
  display: none;
}

.specialMenu__image1 {
  width: 100%;
  height: auto;
  margin-bottom: 0px;
}
.specialMenu__image2 {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}
.specialMenu__image3 {
  width: 50%;
  height: auto;
  margin-bottom: 0px;
}

.specialMenu__image11, .specialMenu__image12, .specialMenu__image13 {
  width: 50%;
  height: auto;
  margin-bottom: 20px;
  /* display: none; */
}
.app__menu-image_container {
  flex: 1 1 45%; /* Adjust size depending on the screen */
  max-width: 45%; /* Ensures images don't grow too large */
}

.app__menu-image {
  width: 100%;
  height: auto;
  border-radius: 10px; /* Adds a slight rounding to images */
}

@media screen and (min-width: 2000px) {
  .custom__button,
  .p__cormorant {
    font-size: 37px;
    line-height: 67px;
  }

  .p__opensans {
    font-size: 30px;
    line-height: 50px;
  }

  .headtext__cormorant {
    font-size: 150px;
    line-height: 210px;
  }

  .spoon__img {
    width: 80px;
  }
}

@media screen and (max-width: 1150px) {
  .app__wrapper {
    flex-direction: column;
  }

  .app__wrapper_img {
    margin: 5rem 0 0 0;
  }
  .app__wrapper_info {
    margin-left: 5rem;
  }

  .app__wrapper_img-reverse {
    margin: 0 0 5rem 0;
  }

  .app__wrapper_img img {
    width: 100%;
  }
  .p__opensans1 {
    font-size: 25px; /* Slightly increased paragraph font size */
    line-height: 33px; /* Adjust line-height */
  }

  .custom__button1 {
    font-size: 22px; /* Increased font size for the button */
    padding: 0.6rem 1.8rem; /* Adjust padding to balance with the larger font */
  }
  .p__opensans {
    font-size: 24px;
    /* width: 150%; */
  }
  .specialMenu__image11, .specialMenu__image12, .specialMenu__image13 {
    width: 100%; /* Set images to take full width */
    margin-bottom: 15px;
    /* width: 50%; */
  height: auto;
  margin-bottom: 20px;
  }
  .specialMenu__image1, .specialMenu__image2, .specialMenu__image3 {
    /* width: 50%;
    height: auto;
    margin-bottom: 20px; */
    display: none;
  }

  .app__menu-heading1 {
    font-size: 5rem; /* Adjust font size */
    font-weight: 600;
  }
  .app__menu1 img {
    max-width: 100%; /* Keep image size within the container */
  }

  .app__menu-image_container {
    flex: 1 1 100%;
    max-width: 100%;
    margin-bottom: 20px;
  }
  .app__specialMenu-images1 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 850px) {
  .section__padding {
    padding: 4rem;
  }
  .app__wrapper_info {
    margin-left: 0rem;
  }
}

@media screen and (max-width: 650px) {
  .section__padding {
    padding: 4rem 2rem;
  }
  
  .p__cormorant {
    font-size: 21px;
  }

  .p__opensans {
    font-size: 20px;
  }
  .specialMenu__image1, .specialMenu__image2, .specialMenu__image3 {
    width: 100%;
    margin-bottom: 10px;
  }

  .app__menu-heading1 {
    font-size: 3.5rem; /* Adjust font size */
  }
}

@media screen and (max-width: 450px) {
  .p__opensans {
    font-size: 18px;
  }

  .p__cormorant {
    font-size: 19px;
  }

  .headtext__cormorant {
    font-size: 45px;
    line-height: 70px;
    /* margin-left: 0px; */
  }
  .p__opensans1 {
    font-size: 20px; /* Slightly increased paragraph font size */
    line-height: 33px; /* Adjust line-height */
  }

  .custom__button1 {
    font-size: 22px; /* Increased font size for the button */
    padding: 0.6rem 1.8rem; /* Adjust padding to balance with the larger font */
  }
  .app__menu-heading1 {
    font-size: 3rem; /* Smaller font for very small screens */
  }

  .specialMenu__image1, .specialMenu__image2, .specialMenu__image3 {
    width: 100%;
    margin-bottom: 5px;
  }
  .app__wrapper_info {
    margin-left: 0rem;
  }
}

@media screen and (max-width: 320px) {
  .p__opensans1 {
    font-size: 20px; /* Slightly increased paragraph font size */
    line-height: 25px; /* Adjust line-height */
  }
}

