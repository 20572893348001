html, body {
  margin: 0;
  padding: 0;
  /* overflow-x: hidden; */ /* Do not hide overflow here */
}
section, .content, .main {
  max-width: 100%; /* Prevent specific sections from causing overflow */
  overflow-x: hidden;
}
.app__navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  padding: 1rem 20rem;
  position: fixed;
  top: 0;
  z-index: 9999;
  transition: background 0.32s ease-in-out; /* Only transition for background */
}

.app__navbar-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}
.app__navbar.scrolled {
  /* padding: 0.5rem 20rem; */
  background: #ffffff;
  /* justify-content: space-between; Aligns logo to the left and menu to the right */
}


.app__navbar-logo img {
  width: 140px;
}

.app__navbar-left,
.app__navbar-right {
  display: flex;
  align-items: center;
}

.app__navbar-links {
  display: flex;
  list-style: none;
}

.app__navbar-links li {
  margin: 0 1rem;
  cursor: pointer;
  color: rgb(0, 0, 0);
  font-weight: 600;
}

.app__navbar-links li:hover {
  color: var(--color-grey);
}

.app__navbar-smallscreen {
  display: none;
}

.app__navbar-login {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.app__navbar-login a {
  margin: 0 1rem;
  text-decoration: none;
  transition: 0.5s ease;
  color: rgb(0, 0, 0);
  font-weight: 600;
}

.app__navbar-login a:hover {
  border-bottom: 2px solid rgb(255, 255, 255);
}

.app__navbar-login div {
  width: 1px;
  height: 30px;
  background: var(--color-grey);
}


.app__navbar-smallscreen_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: var(--color-black);
  transition: 0.5s ease;
  flex-direction: column;
  z-index: 5;
}

.app__navbar-smallscreen_overlay .overlay__close {
  font-size: 27px;
  color: var(--color-golden);
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}

.app__navbar-smallscreen_links {
  list-style: none;
}

.app__navbar-smallscreen_links li {
  margin: 2rem;
  cursor: pointer;
  color: var(--color-golden);
  font-size: 2rem;
  text-align: center;
  font-family: var(--font-base);
  font-weight: 600;
}

.app__navbar-smallscreen_links li:hover {
  color: var(--color-white);
}

.booktable__button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #ffffff; /* White background */
  color: #000000; /* Black text */
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  border: 2px solid #000000; /* Black border */
  border-radius: 5px; /* Rounded corners */
  transition: background-color 0.3s ease, color 0.3s ease;
}



@media screen and (min-width: 2000px) {
  .app__navbar-logo img {
    width: 210px;
  }
}

@media screen and (max-width: 1150px) {
  .app__navbar-left,
  .app__navbar-right {
    display: none;
  }

  .app__navbar-smallscreen {
    display: flex;
  }
  .app__navbar {
    padding: 1rem 7rem;
    justify-content: space-between;
    width: 100%; /* Ensure the navbar does not stretch */
  }
  .app__navbar-logo {
    justify-content: flex-start;
    padding-left: 10px;
  }
}

/* Responsive Changes: On smaller screens */
@media screen and (max-width: 768px) {
  /* Shift the logo to the left and show the hamburger menu on the right */
  .app__navbar {
    padding: 1rem 3rem;
    justify-content: space-between;
    width: 100%; /* Ensure the navbar does not stretch */
  }

  .app__navbar-logo {
    justify-content: flex-start;
    padding-left: 10px;
  }

  .app__navbar-smallscreen {
    display: flex;
    justify-content: flex-end;
  }

  .app__navbar-left,
  .app__navbar-right {
    display: none;
  }
}

@media screen and (max-width: 650px) {
  .app__navbar {
    padding: 1rem 2rem;
    justify-content: space-between;
    max-width: 100%; /* Ensure the navbar does not stretch */
  }
  .app__navbar-logo {
    justify-content: flex-start;
    padding-left: 10px;
  }

  .app__navbar-smallscreen {
    display: flex;
    justify-content: flex-end;
  }

  .app__navbar-login {
    display: none;
  }

  .app__navbar-logo img {
    width: 140px;
  }
  .app__navbar-left,
  .app__navbar-right {
    display: none;
  }
  
}

@media screen and (max-width: 425px) {
  .app__navbar {
    padding: 1rem 1rem;
    /* justify-content: space-between; */
    width: 100%; /* Ensure the navbar does not stretch */  }

  .app__navbar-logo img {
    width: 120px; /* Further reduce logo size */
  }
}