.app__video {
    height: 100vh;
    position: relative;
}

.app__video video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.app__video-overlay {
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.65); /* Adjust opacity as needed */
}

.app__video-text {
    color: white;
    font-size: 3rem; /* Adjust size as needed */
    text-align: center;
    padding: 0 20px; /* Adjust padding as needed */
}
/* .app__header {
    background-color: var(--color-white);
} */
.app__video {
    height: 100vh;
    position: relative;
}

.app__video video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.app__video-overlay {
    position: absolute;
    inset: 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px;
    box-sizing: border-box;
}




.app__header-h1 {
    font-family: 'Roboto', sans-serif;
    color: var(--color-white);
    font-weight: 400;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    line-height: 117px;
    font-size: 90px;
}

.app__header-img img {
    width: 80%;
}

@media screen and (min-width: 2000px) {
    .app__header-h1 {
        font-size: 180px;
        line-height: 210px;
    }
}

@media screen and (max-width: 1150px) {
    .app__header-h1 {
        font-size: 90px;
    }
}

@media screen and (max-width: 650px) {
    .app__header-h1 {
        font-size: 70px;
        line-height: 87px;
    }
    .app__video {
        height: 50vh; /* Reduced section height */
      }
}

@media screen and (max-width: 450px) {
    .app__header-h1 {
      font-size: 66px; /* Increased font size for h1 */
      line-height: 80px; /* Adjust line-height */
    }
  }

  @media screen and (max-width: 320px) {
    .app__header-h1 {
      font-size: 50px; /* Increased font size for h1 */
      line-height: 50px; /* Adjust line-height */
    }
  }
  