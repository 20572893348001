.app__video {
    height: 100vh;
    position: relative;
}

.app__video video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.app__video-overlay {
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.65); /* Adjust opacity as needed */
}

.app__video-text {
    color: white;
    font-size: 3rem; /* Adjust size as needed */
    text-align: center;
    padding: 0 20px; /* Adjust padding as needed */
}
